<template>
	<div class="card card-custom card-stretch gutter-b">
		<div class="card-header border-0 pt-5">
			<h3 class="card-title align-items-start flex-column">
				<span class="card-label font-weight-bolder text-dark">{{ $t('VIEWS.MessageAdministration.WhatsApp.title') }}</span>
			</h3>
			<div class="card-toolbar">
				<a href="" v-on:click.prevent="showSMS('new')" class="btn btn-icon btn-light btn-sm mx-1" :class="!hasPermission('action.survey.add') ? 'disabled' : ''">
					<i class="fas fa-plus text-primary"></i>
				</a>
			</div>
		</div>
		<div class="card-body py-3">
			<ContentLoading size="1.5" v-if="pageProcesses.isLoading"></ContentLoading>
			<NoData v-if="whatsappList.length === 0 && !pageProcesses.isLoading"></NoData>
			<div class="col-12 prinor-table" v-if="whatsappList.length > 0 && !pageProcesses.isLoading">
				<b-table ref="surveysTable" :data="whatsappList" :paginated="true" :per-page="15" default-sort="created_at" :default-sort-direction="'desc'" :sort-icon="'caret-up'">
					<b-table-column field="name" :label="$t('GENERAL.Forms.name')" v-slot="props" :sortable="true" :searchable="true" cell-class="text-valign">
						<template>
							<span class="truncate">{{ props.row.name }}</span>
						</template>
					</b-table-column>
					<b-table-column field="created_at" :label="$t('VIEWS.Survey.All.createdAt')" v-slot="props" :sortable="true" :searchable="false" cell-class="text-valign">
						<template>
							<span class="truncate">{{ new Date(props.row.created_at).toLocaleDateString() }}</span>
						</template>
					</b-table-column>
					<b-table-column field="updated_at" :label="$t('GENERAL.General.updatedAt')" v-slot="props" :sortable="true" :searchable="false" cell-class="text-valign">
						<template>
							<span class="truncate">{{ new Date(props.row.updated_at).toLocaleDateString() }}</span>
						</template>
					</b-table-column>
					<b-table-column field="id" :label="$t('GENERAL.General.actions')" v-slot="props" cell-class="text-right" header-class="text-right" width="15rem">
						<template>
							<a href="" v-on:click.prevent="showSMS(props.row.id)" class="btn btn-icon btn-light btn-sm m-1" :class="!hasPermission('action.survey.edit') ? 'disabled' : ''" :title="$t('MENU.Survey.editSurvey')">
								<i class="fas fa-edit text-primary"></i>
							</a>
							<a href="" @click.prevent="onDelete(props.row.id, props.row.name)" class="btn btn-icon btn-light btn-sm m-1" :class="!hasPermission('action.survey.delete') ? 'disabled' : ''" :title="$t('VIEWS.Survey.All.delete')">
								<i class="far fa-trash-alt text-danger"></i>
							</a>
						</template>
					</b-table-column>
				</b-table>
			</div>
		</div>
		<b-modal
			ref="edit-modal"
			content-class="modal-border"
			size="xl"
			:title="$t('VIEWS.MessageAdministration.WhatsApp.edit') + ': ' + whatsapp.name"
			:cancel-title="$t('GENERAL.Buttons.cancel')"
			:ok-title="$t('GENERAL.Forms.save')"
			v-on:ok="onUpdate"
			v-on:hidden="resetForm"
			scrollable
		>
			<div class="row">
				<div class="col-12">
					<b-form-group>
						<label>{{ $t('VIEWS.MessageAdministration.Mail.name') }}:</label>
						<b-input type="text" v-model="whatsapp.name"></b-input>
					</b-form-group>
				</div>
				<div class="col-12 col-md-6">
					<b-form-group :label="$t('VIEWS.MessageAdministration.Mail.organization')" v-slot="{ ariaDescribedby }">
						<b-form-radio-group v-model="whatsapp.organization" :aria-describedby="ariaDescribedby">
							<b-form-radio :value="false">{{ $t('GENERAL.General.no') }}</b-form-radio>
							<b-form-radio :value="true">{{ $t('GENERAL.General.yes') }}</b-form-radio>
						</b-form-radio-group>
					</b-form-group>
				</div>
			</div>
			<div class="row">
				<div class="col-12">
					<b-form-group>
						<label>{{ $t('VIEWS.MessageAdministration.WhatsApp.template') }}</label>
						<b-form-select :options="whatsappTemplates" v-model="whatsapp.template_id" text-field="message" value-field="id" v-on:change="selectWhatsappTemplate"></b-form-select>
					</b-form-group>
				</div>
				<div class="col-12">
					<b-form-group>
						<label>{{ $t('VIEWS.MessageAdministration.Mail.message') }}:</label>
						<b-textarea v-model="whatsapp.message" rows="6" ref="message" readonly></b-textarea>
					</b-form-group>
				</div>
			</div>
			<div class="row">
				<div class="col-12">
					<b-form-group :label="$t('VIEWS.MessageAdministration.Mail.organization')" v-slot="{ ariaDescribedby }">
						<b-form-radio-group v-model="whatsapp.gender_model" :aria-describedby="ariaDescribedby">
							<b-form-radio :value="false">{{ $t('GENERAL.General.no') }}</b-form-radio>
							<b-form-radio :value="true">{{ $t('GENERAL.General.yes') }}</b-form-radio>
						</b-form-radio-group>
					</b-form-group>
				</div>
				<div class="col-12">
					<b-form-group :label="$t('VIEWS.MessageAdministration.Mail.recipient')" v-slot="{ ariaDescribedby }">
						<b-form-radio-group v-model="whatsapp.recipient_type" :aria-describedby="ariaDescribedby">
							<b-form-radio value="subscriber">{{ $t('VIEWS.MessageAdministration.Mail.subscriber') }}</b-form-radio>
							<b-form-radio value="import">{{ $t('VIEWS.MessageAdministration.Mail.fromImport') }}</b-form-radio>
						</b-form-radio-group>
					</b-form-group>
				</div>
				<div class="col-12 col-md-6" v-if="whatsapp.gender_model">
					<div class="row">
						<div class="col-12">
							<b-form-group>
								<label>{{ $t('VIEWS.MessageAdministration.WhatsApp.template') }}</label>
								<b-form-select :options="whatsappTemplates" v-model="whatsapp.template_female_id" text-field="message" value-field="id" v-on:change="selectWhatsappTemplate($event, 'female')"></b-form-select>
							</b-form-group>
						</div>
						<div class="col-12">
							<b-form-group>
								<label>{{ $t('VIEWS.MessageAdministration.Mail.message') }}:</label>
								<b-textarea v-model="whatsapp.female" rows="6" ref="female" readonly></b-textarea>
							</b-form-group>
						</div>
					</div>
				</div>
				<div class="col-12 col-md-6" v-if="whatsapp.gender_model">
					<div class="row">
						<div class="col-12">
							<b-form-group>
								<label>{{ $t('VIEWS.MessageAdministration.WhatsApp.template') }}</label>
								<b-form-select :options="whatsappTemplates" v-model="whatsapp.template_male_id" text-field="message" value-field="id" v-on:change="selectWhatsappTemplate($event, 'male')"></b-form-select>
							</b-form-group>
						</div>
						<div class="col-12">
							<b-form-group>
								<label>{{ $t('VIEWS.MessageAdministration.Mail.message') }}:</label>
								<b-textarea v-model="whatsapp.male" rows="6" ref="male" readonly></b-textarea>
							</b-form-group>
						</div>
					</div>
				</div>
			</div>
			<div class="row">
				<div class="col-12 prinor-table" v-if="whatsapp.placeholder.length > 0">
					<b-table :data="whatsapp.placeholder" default-sort="number" :default-sort-direction="'asc'">
						<b-table-column field="number" :label="$t('VIEWS.MessageAdministration.WhatsApp.position')" v-slot="props" :sortable="false" :searchable="false" cell-class="text-valign">
							<template>
								<span class="truncate">{{ props.row.number }}</span>
							</template>
						</b-table-column>
						<b-table-column field="placeholder" :label="$t('VIEWS.MessageAdministration.WhatsApp.placeholder')" v-slot="props" :sortable="false" :searchable="false" cell-class="text-valign">
							<template>
								<b-input :id="'whatsapp-message' + props.row.number" type="text" v-model="props.row.placeholder"></b-input>
								<b-popover :target="'whatsapp-message' + props.row.number" triggers="focus" placement="bottom" v-if="whatsapp.personalised === true">
									<h6>{{ $t('VIEWS.Survey.Create.Placeholder.title') }}</h6>
									<treeselect :multiple="false" v-model="selectedPlaceholder" :options="placeholders.options" :disable-branch-nodes="true" placeholder="#Platzhalter waehlen" v-on:select="selectPlaceholder($event, props.row.number)" :normalizer="normalizer"/>
								</b-popover>
							</template>
						</b-table-column>
					</b-table>
				</div>
			</div>
		</b-modal>
	</div>
</template>

<script>
import ApiService from '@/core/services/api.service';
import { PRINOR_TOASTS } from '@/core/services/toast.service';
import ContentLoading from '@/view/component/misc/ContentLoading';
import Swal from 'sweetalert2';
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module';
import { PRINOR_PROCESSES } from '@/core/services/actions.service';
import {SET_ACTION} from "@/core/services/store/actions.module";
import {mapGetters} from "vuex";
import {PRINOR_PERMISSION} from "@/core/services/permissions.service";
import Placeholder from "@/data/survey/Placeholder";
import NoData from "@/view/component/misc/NoData";

export default {
	name: 'WhatsApp',
	components: {NoData, ContentLoading },
	data() {
		return {
			whatsappList: [],
			whatsappTemplates: [],
			whatsapp: {
				id: "",
				name: "",
				message: "",
				female: null,
				male: null,
				personalised: false,
				organization: false,
				template_id: "",
				template_male_id: "",
				template_female_id: "",
				placeholder: [],
				gender_model: false,
				recipient_type: 'subscriber',
			},
			placeholders: null,
			selectedPlaceholder: null,
		};
	},
	mounted() {
		this.$store.dispatch(SET_ACTION, []);
		this.$store.dispatch(SET_BREADCRUMB, [
			{
				title: this.$t('VIEWS.MessageAdministration.title'),
			},
			{ title: this.$t('VIEWS.MessageAdministration.WhatsApp.title') },
		]);
		PRINOR_PROCESSES.isLoading();
		this.placeholders = new Placeholder(this);
		this.onGet();
	},
	computed: {
		...mapGetters(['pageProcesses']),

		personalPlaceholder: function() {
			return new Placeholder(this);
		},

		gendersActive: function() {
			return ((this.female !== '' && this.female != null) || (this.male !== '' && this.male != null));
		}
	},
	methods: {
		hasPermission(name) {
			return PRINOR_PERMISSION.action(name);
		},
		selectPlaceholder: function(node, number) {
			if (node.id != null) {
				let filtered = this.whatsapp.placeholder.filter(value => { return value.number === number; });
				if (filtered.length === 1) {
					filtered[0].placeholder += node.id;
				}
			}

			this.selectedPlaceholder = null;
		},
		normalizer: function(node) {
			return {
				id: node.id,
				label: node.label,
				children: node.children,
			};
		},
		selectWhatsappTemplate: function(id, type = 'message') {
			let filtered = this.whatsappTemplates.filter(value => {
				return value.id === id;
			});
			if (filtered.length === 1) {
				this.whatsapp.template_id = filtered[0].id;
				this.whatsapp[type] = filtered[0].message;

				let regex = /\{\{([0-9]+)\}\}/g;
				let matches = this.whatsapp[type].match(regex);
				if (matches != null && matches.length > 0) {
					this.whatsapp.personalised = true;
					this.whatsapp.placeholder = [];
					matches.forEach(match => {
						this.whatsapp.placeholder.push({
							number: match,
							placeholder: '',
						});
					});
				}
				else {
					this.whatsapp.personalised = false;
				}
			}
		},
		showSMS: function(id) {
			if (id !== 'new') {
				let filtered = this.whatsappList.filter(value => {
					return value.id === id;
				});
				if (filtered.length === 1) {
					this.whatsapp = filtered[0];
					this.whatsapp.placeholder = this.whatsapp.settings.placeholder;
					this.whatsapp.template_id = this.whatsapp.settings.template_id;

					this.whatsapp.gender_model = this.gendersActive;

					if (!('recipient_type' in this.whatsapp))
						this.whatsapp.recipient_type = 'subscriber';

					if (this.whatsapp.company_id == null) {
						this.whatsapp.organization = true;
					}
					else {
						this.whatsapp.organization = false;
					}

					this.$refs['edit-modal'].show();
				}
			} else {
				this.whatsapp.id = 'new';
				this.whatsapp.female = null;
				this.whatsapp.male = null;
				this.whatsapp.gender_model = this.gendersActive;

				this.$refs['edit-modal'].show();
			}
		},
		onGet: function() {
			this.isLoading = true;
			this.get().then(data => {
				this.whatsappList = data.data.message_templates;
				this.whatsappTemplates = data.data.whatsapp_templates;
				this.whatsappTemplates.unshift({id: '', message: 'Bitte wählen Sie eine WhatsApp Vorlage aus...'})
			});
		},
		get: function() {
			return new Promise(resolve => {
				ApiService.setHeader();
				ApiService.get('message-template/whatsapp').then(data => {
					PRINOR_PROCESSES.reset();
					if (ApiService.checkSuccess(data)) {
						if (data.data.success === true) {
							resolve(data.data);
						} else {
							PRINOR_TOASTS.general.error(this, data.data.error);
						}
					}
				});
			});
		},
		resetForm: function() {
			this.whatsapp = {
				id: "",
				name: "",
				message: "",
				female: null,
				male: null,
				personalised: false,
				organization: false,
				template_id: "",
				template_male_id: "",
				template_female_id: "",
				placeholder: [],
				gender_model: false,
				recipient_type: 'subscriber',
			};
		},
		onUpdate() {
			PRINOR_PROCESSES.isUpdating();
			this.update().then(() => {
				this.resetForm();
				this.onGet();
			});
		},
		update() {
			return new Promise(resolve => {
				ApiService.setHeader();
				ApiService.post('message-template/whatsapp', this.whatsapp).then(data => {
					PRINOR_PROCESSES.reset();
					if (ApiService.checkSuccess(data)) {
						if (data.data.success === true) {
							PRINOR_TOASTS.save.confirmation(this);
						} else {
							PRINOR_TOASTS.save.error(this, data.data.error);
						}

						resolve(data.data);
					}
				});
			});
		},
		delete: function(id) {
			return new Promise(resolve => {
				ApiService.setHeader();
				ApiService.delete('message-template/whatsapp/' + id).then(data => {
					PRINOR_PROCESSES.reset();
					if (ApiService.checkSuccess(data)) {
						if (data.data.success === true) {
							resolve(data.data);
						} else {
							PRINOR_TOASTS.general.error(this, data.data.error);
						}
					}
				});
			});
		},
		onDelete: function(id, name) {
			Swal.fire({
				title: this.$t('VIEWS.Survey.All.delete'),
				text: this.$t('VIEWS.Survey.All.deleteText', { name: name }),
				icon: 'question',
				confirmButtonText: this.$t('GENERAL.Forms.delete'),
				showCancelButton: true,
				cancelButtonText: this.$t('GENERAL.Buttons.cancel'),
				customClass: {
					confirmButton: 'btn btn-danger',
					cancelButton: 'btn btn-secondary',
				},
				allowOutsideClick: false,
				allowEscapeKey: false,
				allowEnterKey: false,
			}).then(result => {
				if (result.isConfirmed) {
					this.delete(id).then(data => {
						if (data.success === true) {
							this.onGet();
						} else {
							PRINOR_TOASTS.delete.error(this);
						}
					});
				}
			});
		},
	},
};
</script>

<style>
.text-valign {
	vertical-align: middle !important;
}
</style>
